import _ from 'underscore';
const functions = {
    getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    translateNumber(index) { //数组下标
        let numberArr = ['一','二','三','四','五','六','七','八','九','十'];
        if (index < 10) {
            return numberArr[index];
        } else if (index >= 10 && index < 20) {
            let sub = index - 10;
            if (sub < 9) {
                return '十' + numberArr[sub];
            } else {
                return '二十';
            }
        }
    },
    translateNumberByObject(object, key) {
        let keysArr = [];
        _.map(object, (item, index) => {
            if (item.length) {
                keysArr.push(index);
            }
        });
        let _index = keysArr.indexOf(key);
        return this.translateNumber(_index);
    }
}
export default functions;
<template>
  <div v-if="data.length > 0">
    <div class="questiontype-content" v-for="(item,index) in data" :key="index">
      <div class="questiontype-top">
        <div class="Title"><span class="Title-span">【题文】{{index + 1}}、</span><span v-html="item.title" class="html-span"></span></div>
      </div>
      <div class="answer-analysis-foot">
        <div class="answer-analysis-foot-left">
          <span>难度:{{item.difficulty_id === 1?'容易':item.difficulty_id === 2?'较易':item.difficulty_id === 3?'普通':item.difficulty_id === 4?'较难':'困难'}}</span>
          <span>题型:填空题</span>
          <span>组卷次数:{{item.use_num}}次</span>
        </div>
        <div class="answer-analysis-foot-right">
          <el-button :type="isAdd(item) ? 'danger' : 'primary'" :icon="isAdd(item) ? 'el-icon-minus':'el-icon-plus'" class="add-analysisBtn" @click="addPaper(item)">
            <!--            <i class="iconfont" >{{isAdd(item) ? '&#xe668;':'&#xe609;'}}</i>-->
            {{isAdd(item) ? '从试卷移除' : '加入试卷'}}
          </el-button>
        </div>
      </div>
      <div class="answer-analysis" v-show="isShow">
        <div class="answer-analysis-top" v-if="item.options && item.options.length">
          <span class="answer-analysis-label">答案：</span>
          <span class="answer-analysis-text" v-for="optionItem in item.options">
            {{optionItem.name}}
          </span>
        </div>
        <div class="answer-analysis-bottom">
          <span class="answer-analysis-label">解析：</span>
          <span class="answer-analysis-text" v-html="item.analysis" v-if="item.analysis.length > 0"></span>
          <span class="answer-analysis-text" v-else>略</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "underscore";
export default {
  name: "FillblanksModule",
  props: {
    data: {
      type: Array,
      default: []
    },
    isShow: {
      type: Boolean,
      default: false
    },
    qIds: {
      type: Array,
      default: []
    },
  },
  methods: {
    //加入试卷
    addPaper(item) {
      console.log(item)
      this.$emit('putInPaper', item);
    },
    isAdd(item) {
      let value = _.find(this.qIds, function(id) {
        return id === item.theory_topic_id;
      });
      if (value) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>

<style scoped lang="scss">
ul,li{
  list-style: none;
  margin: 0;
  padding: 0;
}
::v-deep .html-span{
  p{
    padding: 0;
    margin: 0;
  }
}
.questiontype-content{
  border: 1px solid #D2D2D2;
  font-size: 14px;
  color: #333;
  margin-bottom: 20px;
  .questiontype-top{
    display: flex;
    display: -webkit-flex;
    font-size: 14px;
    color: #333;
    font-weight: 500;
    /*padding: 4px 4px;*/
    .Title{
      display: -webkit-box;
      display: flex;
      font-size: 14px;
      color: #333;
      font-weight: 500;
      padding: 26px 0 0 30px;
      /*padding: 4px 4px;*/
      /*.Title-span{*/
      /*    min-width:36px;*/
      /*    display: inline-block;*/
      /*}*/
    }
  }
  .Options-list{
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    padding: 16px 0 0 30px;
    /*padding: 10px;*/
    li{
      width: 100%;
      line-height: 48px;
    }
  }
  .answer-analysis{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    margin: 10px 0 16px;
    .answer-analysis-top{
      font-size: 14px;
      margin:10px 0 0 30px;
      color: #666666;
      .answer-analysis-text{
        display: inline-block;
        color: #1AB163;
        &:last-of-type{
          &:after{
            content: '';
          }
        }
        &:after{
          content: '、';
        }
      }
    }
    .answer-analysis-label{
      //color: #409eff;
    }
    .answer-analysis-bottom{
      color: #666666;
      font-size: 14px;
      margin:10px 0 0 30px;
      .answer-analysis-text{
        display: inline-block;
      }
    }
  }
  .answer-analysis-foot{
    display: flex;
    display: -webkit-flex;
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    background: #E7F6EF;
    height: 50px;
    line-height: 50px;
    padding: 0 22px 0 30px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    .answer-analysis-foot-left{
      display: flex;
      display: -webkit-flex;
      flex: 1;
      width: 1%;
      span{
        display: inline-block;
        margin-right: 50px;
        color: #666666;
        font-size: 14px;
      }
    }
    .add-analysisBtn{
      padding:10px;
      i{
        font-size: 14px;
        color: #FFFFFF;
      }
      ::v-deep span{
        margin-left: 0;
      }
    }
  }
}
.null-data{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:14px;
  height: 558px;
}
::v-deep p {
  display: inline-block;
}
</style>

<template>
<div class="TestColumn-content">
  <el-badge :value="data.paper_number" class="item">
    <div class="TestColumn-title" id="showArea" @click="toBasket">
      <span class="TestColumn-Icon"><i class="iconfont">&#xe63e;</i></span>
    </div>
  </el-badge>
  <div class="TestColumn-dialog"  v-show="basketShow">
    <div class="TestColumn-dialog-top">
      我的试卷
      <div class="triangle">

      </div>
    </div>
    <div class="TestColumn-dialog-body">
      <div class="TestColumn-dialog-num">最新加入试题<span style="margin: 0 10px;">(共{{data.paper_number}}题)</span></div>
      <div class="TestColumn-dialog-type" v-for="(item,index) in data.content" v-show="item.length>0">
        <span class="TestColumn-dialog-title">{{index | translateType}}</span>
        <span class="TestColumn-dialog-title">{{item.length}}题</span>
        <span class="TestColumn-dialog-del" @click="removeQuestion(index)"><i class="iconfont">&#xe617;</i></span>
      </div>
      <div class="TestColumn-dialog-add" v-show="data.paper_number === 0"> 请先加入试题</div>
      <div class="TestColumn-dialog-foot">
        <div class="TestColumn-dialog-foot-left">
          <span @click="emptyTopic()">清空全部</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "TestColumnModule",
  data(){
    return{
      basketShow: false,
    }
  },
  props: ['data'],
  filters: {
    translateType: function (value) {
      let name = '';
      switch (value) {
        case 'single':
          name = '单选题';
          break;
        case 'multiple':
          name = '多选题';
          break;
        case 'judge':
          name = '判断题';
          break;
        case 'fillBlank':
          name = '填空题';
          break;
        case 'askAnswer':
          name = '问答题';
          break;
      }
      return name;
    }
  },
  mounted() {
    let num = 0;
    for(let i in this.data.content){
      num += this.data.content[i].length
    }
    this.data.paper_number = num;
  },
  methods:{
    removeQuestion(index){
      this.$confirm('确定删除试题?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        customClass:'green-message-box',
      }).then(() => {
        // 试题总数 扣除 被删除题型 的题目数量
        this.$emit('removeOne', index);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 把试题蓝中的每一种题型清零
    emptyTopic(){
      this.$confirm('是否清空该试卷所有试题，清空后将无法回复?', '清空', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass:'green-message-box',
        type: 'warning'
      }).then(() => {
        //循环题型栏中的题型数组
        this.$emit('clearAll', true);
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 试题栏点击事件
    toBasket() {
      this.basketShow = !this.basketShow;
    }


  }
}
</script>

<style scoped lang="scss">
.TestColumn-content{
  position: relative;
  .item{
    &::v-deep .el-badge__content{
      background: #E84932;
      border-radius: 8px;
      height: 16px;

    }
  }
  .TestColumn-title{
    cursor: pointer;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    align-items: center;
    -webkit-align-items: center;
    width: 48px;
    border-radius: 8px;
    font-size: 12px;
    .TestColumn-Icon{
      display: inline-block;
      height: 32px;
      line-height: 32px;
      -webkit-text-fill-color: #E84932;
      color: #E84932;
      cursor: pointer;
      i{
        font-size: 32px;
      }
    }
  }
  .TestColumn-dialog{
    z-index: 999;
    min-width: 220px;
    position: absolute;
    right: -4px;
    top: 54px;
    text-align: left;
    color: #333;
    .TestColumn-dialog-top{
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      color: #333333;
      padding: 0 16px;
      background: #E7F6EF;
      font-weight: 500;
      position: relative;
      border: 1px solid #DCDCDC;
      .triangle{
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 6px solid #DCDCDC;
        position: absolute;
        right: 22px;
        top: -6px;
      }
    }
    .TestColumn-dialog-body{
      border: 1px solid #D2D2D2;
      border-top: none;
      background-color: #fff;
      .TestColumn-dialog-num{
        font-size: 14px;
        color: #666666;
        padding: 0px 14px;
        line-height: 36px;
        height: 36px;
        /* em {*/
        /*    font-style: normal;*/
        /*    color: #409eff;*/
        /*}*/
      }
      .TestColumn-dialog-type{
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        padding: 0px 10px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        .TestColumn-dialog-title{
          color: #333333;
          font-size: 14px;
          font-weight: 500;
        }
        .TestColumn-dialog-del{
          font-size: 14px;
          color: #FF2000;
          cursor: pointer;
        }
      }
      .TestColumn-dialog-add {
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        padding: 20px;
        font-size: 14px;
      }
    }
    .TestColumn-dialog-foot{
      border-top: 1px solid #DCDCDC;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      align-items: center;
      font-size: 14px;
      /*padding: 0 10px;*/
      height: 32px;
      background: #fff;
      .TestColumn-dialog-foot-left{
        display: -webkit-box;
        display: flex;
        -webkit-box-flex: 1;
        flex: 1;
        width: 1%;
        justify-content: center;
        span{
          color: #999999;
          cursor: pointer;
          font-size: 14px;
        }
      }
      .preview-btn{
        padding: 6px 4px;
      }
    }

  }
}
</style>
